.common {
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 1.2rem 3.2rem;
  cursor: pointer;
  text-align: center;
  display: block;
  border-radius: 4.8rem;
}

.red {
  composes: common;
  background-color: red;
  color: white;
  border: 0;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: darkred;
  }
}

.redTransparent {
  composes: common;
  border: 2px red solid;
  background-color: transparent;
  color: red;
  transition: border-bottom-color, border-left-color, border-right-color,
    border-top-color 0.2s ease;

  &:hover {
    border-color: darkred;
  }
}
