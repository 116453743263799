@import-normalize;
@import "./styles/common.scss";

html {
  font-family: var(--fontFamily);
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --fontFamily: "Roboto", sans-serif;
  --darkBlue: #0b3a5c;
  --lightBlue: #44b4e5;
  --red: red;

  --contentWidth: 110rem;
  --grid8: 0.8rem;
  --grid12: 1.2rem;
  --grid16: 1.6rem;
  --grid24: 2.4rem;
  --grid32: 3.2rem;
  --grid40: 4rem;
  --grid48: 4.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-family: var(--fontFamily);
  font-weight: 200;
}

button:focus,
a:focus {
  outline: 0.2rem dashed var(--lightBlue);
}

div {
  width: 100%;
}

p {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #8f8f8f;
}

h1 {
  font-size: 6rem;
  margin: 4rem 0;
}

h2 {
  font-size: 5rem;
  margin: 3rem 0;
}

h3 {
  font-size: 3rem;
  margin: 2rem 0;
}

.banner {
  height: 760px;
  padding-top: 50px;
  background-image: url("images/banner-bg.jpg");
  background-size: cover;
  background-position: center center;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: black;
  }
}

.bannerContent {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bannerCta {
  margin: 0 auto;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 1.2rem 3.2rem;
  cursor: pointer;
  text-align: center;
  display: block;
  border-radius: 4.8rem;
  width: 20rem;
  text-decoration: none;

  background-color: red;
  color: white;
  border: 0;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: darkred;
  }
}

.bannerTitle {
  color: white;
  max-width: 720px;
  margin: 20rem auto 8rem auto;
  text-align: center;
}

.section {
  max-width: var(--contentWidth);
  margin: 0 auto;
}

.servicesBackground,
.contactBackground {
  width: 100%;
  background-size: cover;
}

.servicesBackground {
  background-image: url("./images/service-background.jpg");
}

.contactBackground {
  background-image: url("images/contact-bg.jpg");
}

.clients,
.about {
  padding: 8rem var(--grid16);

  @media ($tablet) {
    padding: 8rem var(--grid24);
  }

  & > h2 {
    margin-top: 0;
    text-align: center;
  }

  & > p {
    max-width: 720px;
    margin: 0 auto;
    text-align: center;
  }
}

.services {
  padding: 8rem var(--grid16) 5rem var(--grid16);

  @media ($tablet) {
    padding: 8rem var(--grid24) 5rem var(--grid24);
  }

  & > h2 {
    text-align: center;
    color: white;
    margin-top: 0;
  }

  & > p {
    max-width: 720px;
    text-align: center;
    margin: 0 auto 4rem;
  }

  & .servicesContent {
    @media ($tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  & .serviceColumn {
    width: 100%;
    padding: 0 var(--grid16);
    text-align: center;
    margin-bottom: 3rem;

    @media ($tablet) {
      width: calc(100% / 3);
    }

    & > h3 {
      color: white;
    }

    & > svg {
      color: white;
      font-size: 5rem;
    }
  }
}

.contact {
  padding: 8rem var(--grid16) 5rem var(--grid16);

  @media ($tablet) {
    padding: 8rem var(--grid12) 5rem var(--grid12);
  }

  & > h2 {
    text-align: center;
    color: white;
    margin-top: 0;
  }

  & .calloutContainer {
    width: 100%;

    @media ($tablet) {
      width: 50%;
      flex-shrink: 1;
      margin: 0 var(--grid12);
    }

    @media ($desktop) {
      max-width: 41rem;
    }
  }

  & .callout {
    font-size: 3.8rem;
    font-weight: 200;
    text-transform: none;
    color: #8f8f8f;
    letter-spacing: -3px;
    width: 100%;
    text-align: center;

    @media ($tablet) {
      text-align: left;
    }

    & > strong {
      font-weight: 700;
      color: var(--lightBlue);
    }
  }
}

.downArrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  & > img {
    display: block;
    pointer-events: none;
  }
}

.formContainer {
  width: 100%;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.contactForm {
  background-color: white;
  padding: 2rem;
  width: 100%;
  border-radius: 1rem;

  @media ($tablet) {
    padding: 4rem;
  }

  @media ($tablet) {
    width: 50%;
    flex-shrink: 0;
    margin: 0 var(--grid12);
  }
}

.formRow {
  display: flex;
  flex-direction: row;
  padding: 0 0.8rem;

  & + .formRow {
    margin-top: 1.6rem;
  }

  & > div,
  & > button {
    margin: 0 0.8rem;
  }
}

.successContainer {
  border: 1px solid #dff0d8;
  padding: 1.6rem;
  color: #3c763d;
  background-color: #dff0d8;
  font-size: 1.6rem;
}

.errorContainer {
  border: 1px solid #ebccd1;
  padding: 1.6rem;
  color: #a94442;
  background-color: #f2dede;
  font-size: 1.6rem;
}
