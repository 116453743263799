@import "../../styles/common.scss";

.header {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}

.headerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: var(--contentWidth);
  margin: 0 auto;
}

.logo {
  width: 16.5rem;
  margin: var(--grid8) var(--grid16);

  @media ($tablet) {
    margin: var(--grid16) var(--grid24);
  }
}

.navLink {
  display: block;
  line-height: 8rem;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0 1.5rem;
  color: var(--darkBlue);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: var(--lightBlue);
    text-decoration: underline;
  }
}

.navigation {
  display: none;

  @media ($tablet) {
    display: block;
    margin: 0;
  }

  & ul {
    width: 100%;
  }

  & li {
    display: inline-block;
  }
}

.mobileNavigationContainer {
  position: relative;
  align-self: stretch;
  width: 100%;

  @media ($tablet) {
    display: none;
  }
}

.mobileNavigation {
  position: fixed;
  background-color: rgba(#fff, 0.95);
  top: 0;
  left: 0;
  transform: translate(-100%, 0);
  transition: transform 0.5s ease;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  &.isOpen {
    transform: translate(0, 0);
  }

  @media ($tablet) {
    display: block;
  }

  & ul {
    width: 100%;
  }

  & li {
    width: 100%;
    text-align: center;

    @media ($tablet) {
      width: auto;
    }
  }
}

.mobileMenuToggle {
  display: inline-block;
  font-size: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1000;
  height: 100%;
  border: 0;
  width: 66px;
  padding-left: 22px;
  box-sizing: border-box;
  background-color: white;

  .mobileMenuToggleIcon,
  .mobileMenuToggleIcon::before,
  .mobileMenuToggleIcon::after {
    background: black;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    transform: rotate(0deg);
    transition: transform 100ms ease-in, top 100ms ease-in 150ms,
      bottom 100ms ease-in 150ms; // 1
    width: 20px;
  }

  .mobileMenuToggleIcon {
    top: 30px;
    transition: background-color 10ms ease-in 100ms;
  }

  .mobileMenuToggleIcon::before {
    top: -0.5rem;
  }

  .mobileMenuToggleIcon::after {
    bottom: -0.5rem;
  }

  &.isOpen .mobileMenuToggleIcon {
    background-color: transparent;
    transition-delay: 100ms;

    // scss-lint:disable NestingDepth
    &::before,
    &::after {
      background-color: black;
      bottom: auto;
      top: auto;
      transition: transform 100ms ease-in 150ms, top 100ms ease-in,
        bottom 100ms ease-in; // Animate lines from hamburger to 'x'
    }

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
}
