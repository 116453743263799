.label {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.6rem;
}

.input {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid transparent;
  padding: 0.8rem 1.6rem;
  outline: none;
  transition: background-color 0.5s, border-color 0.5s, color 0.5s;
  font-size: 1.6rem;

  &:hover {
    border: 1px solid #d4d4d4;
  }
}

.errorMessage {
  color: var(--red);
  position: absolute;
  font-size: 1.4rem;
  bottom: 0;
  left: 0;
}

.container {
  width: 100%;
  position: relative;
  padding-bottom: 2rem;

  &.error {
    .input {
      border: 1px var(--red) solid;
    }
  }
}
