.container {
  width: 100%;
}

.label {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.6rem;
}

.textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 8.8rem;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid transparent;
  padding: 0.8rem 1.6rem;
  outline: none;
  transition: background-color 0.5s, border-color 0.5s, color 0.5s;
  font-size: 1.6rem;

  &:hover {
    border: 1px solid #d4d4d4;
  }
}
